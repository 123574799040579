<template>
    <div class="support-heading-container mb-4">
    <div class="support-heading">
       {{ heading }}
    </div>
    <div class="support-sub-heading mt-2 mb-4">
       {{ subHeading }}
    </div>
    <div class="line" v-if="isLine"></div>
</div>
</template>
<script>
export default{
    props:{
        heading:String,
        subHeading:String,
        isLine:{
            type:Boolean,
            default:true
        }
    }
}
</script>
<style>
.support-heading{
    color:#292D35;
    font-weight: 500;
    font-size: 48px;
    line-height: 58px;
    text-align: center;
}
.support-sub-heading{
    color:#667085;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
}
.line::after{
    content: '';
    background: #EDEDED;
    height: 1px;
    width: 100%;
    display: block;
}
</style>